import apiClient from '../utilities/api-client';

export const getRepositoryTypes = async () => apiClient.get(`/repository_types`);

export const getRepositories = async () => apiClient.get(`/repositories/all`);
export const getRepository = async (repositoryId) => apiClient.get(`/repositories/${repositoryId}`);
export const createRepository = async (data) => apiClient.post(`/repositories`, data);
export const updateRepository = async (repositoryId, data) => apiClient.put(`/repositories/${repositoryId}`, data);
export const deleteRepository = async (repositoryId) => apiClient.remove(`/repositories/${repositoryId}`);

export const verifyConnection = async (repositoryId) =>
  apiClient.post(`/repositories/${repositoryId}/verify_connection`);
