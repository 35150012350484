import React from 'react';
import { useTranslation } from 'react-i18next';

const DataAnnotationTemplate = ({ filenames, currFilename }) => {
  const { t } = useTranslation();

  const getDataAnnotationContent = (fNs, currFn) => {
    const currentExtension = currFn.split('.')?.pop();
    const fileName = currFn.replace(`.${currentExtension}`, '');

    if (!currentExtension || !fileName) {
      return <span className="p-font-bold">-</span>;
    }

    // if filename is not a csv,xls,xslx return -
    if (!['csv', 'xls', 'xlsx'].includes(currentExtension)) {
      return <span className="p-text-bold">-</span>;
    }

    // search all filenames, if one with the same filename and vmpr
    // extension exists, then output a checkmark
    for (let i = 0; i < fNs.length; i += 1) {
      const ext = fNs[i]?.split('.').pop();
      const fN = fNs[i].replace(`.${ext}`, '');
      if (ext === 'vmpr' && fN === fileName) {
        return (
          <span className="p-d-flex text-green p-jc-center p-ai-center">
            <i className="pi pi-check text-green bg-green rounded-full p-p-1 p-mr-2" />
          </span>
        );
      }
    }

    // reaching here, means the file (csv | xls | xlsx) can be annoated:
    return (
      <a
        className="p-button p-component"
        href="http://vmapperplus.scio.services/tools/vmapper"
        target="_blank"
        rel="noreferrer"
      >
        <span className="p-button-label p-c">{t('ANNOTATE_DATASET')}</span>
      </a>
    );
  };

  return <div className="p-text-center">{getDataAnnotationContent(filenames, currFilename)}</div>;
};

export default DataAnnotationTemplate;
